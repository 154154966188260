export const AssetLibraryFilterStorageKey = 'assetlibraryfilter';
export const AssetPickerRecentlyUsedFolderStorageKey = 'assetPickerRecentlyUsedFolder';
export const injectedAuth0AccessTokenStorageKey = 'injected_access_token';
export const Auth0RedirectUriStorageKey = 'redirectUri';
export const CalendarArrangementStorageKey = 'calendarArrangement';
export const CalendarFilterConfigStorageKey = 'calendarfilterconfig';
export const CollectionsFilterStorageKey = 'collectionsfilter';
export const ContentItemListingColumnFilterStorageKey = 'contentItemListingColumnFilter';
export const ContentItemListingFilterConfigStorageKey = 'entryfilterconfig';
export const ContentPaceTimeIntervalStorageKey = 'contentpacetimeinterval';
export const ContentPaceContentTypeStorageKey = 'contentpacecontenttype';
export const ContentPaceWorkflowStageStorageKey = 'contentpaceworkflowstage';
export const ContentStatusColumnFilterStorageKey = 'contentStatusColumnFilter';
export const ContentStatusFilterConfigStorageKey = 'contentstatusfilterconfig';
export const ContentStatusLanguagesFilterStorageKey = 'contentstatuslanguagesfilter';
export const ContentVariantIdStorageKey = 'contentvariantid';
export const EmailVerificationRedirectUrlKey = 'emailVerificationRedirectUrl';
export const FullTextSearchContextStorageKey = 'fulltextsearchcontext';
export const GuidelinesVisibilityStorageKey = 'guidelinesVisibility';
export const IsNavigationTreeOpenedStorageKey = 'isnavigationtreeopenstoragekey';
export const ListingOrderByStorageKey = 'listingorderby';
export const LivePreviewPreferenceStorageKey = 'livepreviewpreference';
export const OAuthIntegrationNameStorageKey = 'oauthintegrationname';
export const ProjectContainerIdStorageKey = 'currentprojectcontainer';
export const ProjectIdStorageKey = 'currentproject';
export const ProjectOverviewFilterConfigStorageKey = 'projectoverviewfilterconfig';
export const RelationsConfigStorageKey = 'relationsconfigstorage';
export const SelectedItemEditorSpaceIdStorageKey = 'selecteditemeditorspaceid';
export const SpacesFilterStorageKey = 'spacesfilter';
export const SubscriptionUserListingConfigStorageKey = 'subscriptionuserlistingconfig';
export const UserListingConfigStorageKey = 'userlistingconfigstoragekey';
export const WebSpotlightInItemEditingPreferencesStorageKey =
  'webspotlightinitemeditingpreferencesstoragekey';
export const WebSpotlightPreferencesStorageKey = 'webspotlightpreferencesstoragekey';
export const NavigationStateStorageKey = 'navigationstate';
